<template>
	<div class="productDetail">
		<!-- 轮播图 -->
		
		<div class="swiper-box">
			<van-swipe :autoplay="3000" class="swipe" @change="onChange" v-if="productInfo">
				<van-swipe-item v-for="(image, index) in productInfo.images" :key="index">
					<van-image style="width: 100%;height: 100%;" :src="image" />
				</van-swipe-item>
				<template #indicator>
					<div class="custom-indicator">{{ current + 1 }}/{{productInfo.images.length}}</div>
				</template>
			</van-swipe>

		</div>
		<!-- 商品信息 -->

		<div class="product-head">
			<div class="head-top">
				<span class="product-price"><span style="font-size: 16px;">￥</span>{{productInfo.price}}</span>
				<!-- <div class="product-collection">
					<van-icon name="star-o" size="20" /><span>收藏</span>
				</div> -->
			</div>
			<div class="head-middle">
				<p class="name">{{productInfo.name}}</p>
			<!-- 	<div class="share" @click="show = false">
					<van-icon name="share-o" size="15" /><span>分享</span>
				</div> -->
			</div>
			<div class="head-bottom">
				<span>销量 {{productInfo.sales}}</span>
				<span>单位 {{productInfo.unit}}</span>
				<span>库存 {{productInfo.stock}}</span>
			</div>
		</div>
		<div class="store-list">
			<div class="store-number" v-if="productInfo">适用门店({{productInfo.store_list.length}}家)</div>

			<div v-for="(item,index) in productInfo.store_list" :key="index" class="store-item" v-show="isOpen || index < max">
				<div class="left">
					<p class="store-name">{{item.name}}</p>
					<p class="store-address">{{item.address}}</p>
				</div>
				<div class="right">
					<!-- <div class="icon">
						<van-icon name="phone" size="20" />

					</div> -->
					<span class="store-distance">
						<van-icon name="location" size="15" />
						<span v-if="item.distance <= 1000">{{item.distance}}m</span>
						<span v-else>{{(item.distance/1000).toFixed(2)}}km</span>
					</span>
				</div>

			</div>
			
			<button class="btnMoer" v-if="productInfo" v-show="!isOpen && productInfo.store_list.length > max" @click="isOpen = !isOpen">展开更多</button>
			<button class="btnMoer" v-if="productInfo" v-show="isOpen && productInfo.store_list.length > max" @click="isOpen = !isOpen">收起</button>
		</div>
		<!-- 评价 -->
		<!-- <div class="goods-evaluate" v-if="productInfo">
			<van-cell value="" is-link>
				
				<template #title>
					<span class="custom-title">商品评价({{productInfo.talk_list.length}})</span>

				</template>
			</van-cell>
		</div> -->
		<div class="goods-recommend">
			<van-cell value="商品推荐" />

			<div class="recommend-list">
				<div class="recommend-item" v-for="(i,ins) in productInfo.recom_list" :key="ins" @click="indexDetails(i.id)">
					<img :src="i.image" class="item-image">
					<span class="item-name">{{i.name}}</span>
					<span class="item-price"><span style="font-size: 10px;">￥</span>{{i.price}}</span>
				</div>
			</div>
		</div>
		<!-- 选择套餐 -->
		<!-- <div class="setMeal">
			<div style="font-size: 16px;">选择套餐</div>
			<ul class="mealList">
				<li v-for="(v,k) in info.meal" @click="active(k)" :class="{active:index == k}">{{v.name}}</li>
			</ul>
		</div> -->
		<!-- 购买须知 -->
		<!-- <div class="buyKnow">
			<div style="font-size: 16px;">购买须知</div>
			<div>
				<div class="makeWay">
					<p style="width: 6px;height: 6px;background: #FC6228;border-radius: 50%;"></p>
					<p style="font-size: 14px;margin-left: 10px;">预约方式</p>
				</div>
				<div style="font-size: 13px;margin: 10px 0;">无需预约，高峰期需等位</div>
			</div>
			<div>
				<div class="makeWay">
					<p style="width: 6px;height: 6px;background: #FC6228;border-radius: 50%;"></p>
					<p style="font-size: 14px;margin-left: 10px;">营业时间</p>
				</div>
				<div style="font-size: 13px;margin: 10px 0;">周一-周日：11:00-21:00</div>
			</div>
			<div>
				<div class="makeWay">
					<p style="width: 6px;height: 6px;background: #FC6228;border-radius: 50%;"></p>
					<p style="font-size: 14px;margin-left: 10px;">适用规则</p>
				</div>
				<div style="font-size: 13px;margin: 10px 0;">11111111111</div>
			</div>
		</div>
		 -->
		<!-- 详情 -->
		<div class="details">
			<van-divider>商品详情</van-divider>
			<div class="shop-rule-content" v-html="productInfo.detail">

			</div>
		</div>
		<van-action-bar>
			<!-- <van-action-bar-icon icon="chat-o" text="客服" dot /> -->
			<!-- <van-action-bar-icon icon="cart-o" text="购物车" :badge="cartNum" @click="goShopingCart" /> -->
			<van-action-bar-icon icon="shop-o" text="店铺" @click="goShop" />
			<van-action-bar-icon icon="star" text="收藏" @click="addCollect(productInfo.id)" v-if="is_collect"
				color="#ffd100" />
			<van-action-bar-icon icon="star-o" text="收藏" @click="addCollect(productInfo.id)" v-else />
			<!-- <div style="display: flex;width: 100%;" v-if="isShow!=3">
				<van-action-bar-button type="warning" text="加入购物车" @click="addCart" />
				<van-action-bar-button type="danger" text="立即购买" @click="goOrderConfirm" />
			</div> -->
			<div style="display: flex;width: 100%;">

				<van-action-bar-button type="warning" text="立即购买" @click="atOrder(productInfo.id)" v-if="productInfo.stock>0" />
				<van-action-bar-button  @click="noClick" type="warning" text="立即购买"  v-else />
			</div>

		</van-action-bar>

	</div>
</template>

<script>
	import * as index from '@/api/index'
	import { Toast } from 'vant';
	export default {
		data() {
			return {
				images: [],
				productInfo: '',
				index: 0,
				current: 0,
				is_collect: false,
				max: 2, // 默认最多显示的个数；最大行数*每行显示的个数 Number
				isOpen: false, // 是否展开全部信息的标识 Boolean 默认false
			}
		},
		methods: {
			share() {
				var that = this
				upsdk.showSharePopup({
					title: '银联云闪付随机立减大优惠～！',
					desc: '我刚刚使用银联云闪付, 省了30元,大家快来使用吧.',
					shareUrl: location.href,
					picUrl: 'https://youhui.95516.com/web/image/mchnt/coupon/Z00000000138804_logo_list_web.jpg'
				});
			
			},
			// 附件推荐详情
			indexDetails(id) {
				console.log(id)
				this.$router.push({
					path: "/h5/commDetail",
					query: {
						id: id
					}
				})
			},
			onChange(index) {
				this.current = index;
			},
			active: function(k) {
				console.log(k)
				this.index = k;
			},
			orderHome() {
				this.$router.push({
					path: "/h5"
				})
			},
			orderOreder() {
				this.$router.push({
					path: "/orderPage"
				})
			},
			// 详情
			getProductDetails() {
				let params = {
					id: this.$route.query.id,
				}
				index.getProductDetails(params).then((res) => {
					console.log(res)
					this.productInfo = res.data
					this.images = res.data.images
					this.is_collect = res.data.is_collect
				})
			},
			goShop(){
				this.$router.push({
					path: "/h5/shopPage"
				})
			},
			//收藏
			addCollect(id) {
				console.log(id)
				if (this.is_collect) {
					index.delList({
						pid: id
					}).then((res) => {
						console.log(res)
						if (res.code == 200) {
							this.is_collect = !this.is_collect
							this.$toast('取消成功')
							this.getProductDetails()
						}
					})
				} else {
					index.getDddCollect({
						pid: id
					}).then((res) => {
						console.log(res)
						if (res.code == 200) {
							this.is_collect = !this.is_collect
							this.$toast('收藏成功')
							this.getProductDetails()
						}
					})
				}

			},
			atOrder(id) {
				this.$router.push({
					path: "/h5/atOrder",
					query: {
						id: id
					}
				})
			},
			//
			noClick(){
				// console.log(11)   
				Toast('库存不足');
			}
		},
		mounted() {
			this.getProductDetails();
			console.log(this.$route.query.id)
			console.log(window.localStorage.getItem('token'))
		}
	}
</script>

<style lang="less" scoped>
	::v-deep .shop-rule-content p {
		margin: 0;
		width: 100% !important;
		font-size: 16px;
		// margin-bottom: 50px;
		padding: 0 0 55px 0;
	}

	::v-deep .shop-rule-content img {
		margin: 0;
		width: 100% !important;
	}

	.productDetail {
		.details {}

		.swiper-box {
			height: 40vh;

			.swipe {
				position: relative;
				height: 100%;

				.custom-indicator {
					position: absolute;
					bottom: 5px;
					right: 5px;
					font-size: 10px;
					padding: 3px 5px;
					background-color: #999999;
					opacity: 0.5;
					color: #FFFFFF;
				}
			}

		}

		.product-head {
			padding: 10px;
			background-color: #FFFFFF;

			.head-top {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.product-price {
					color: #fe6600;
					font-size: 22px;
					font-weight: bold;
				}

				.product-collection {
					display: flex;
					flex-direction: column;
					font-size: 10px;
					align-items: center;
				}
			}

			.head-middle {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 10px;

				.name {
					font-size: 16px;
					font-weight: bold;
				}

				.share {
					position: absolute;
					right: 0;
					display: flex;
					align-items: center;
					background-color: #999999;
					opacity: 0.3;
					color: #F0F0F0;
					font-size: 12px;
					padding: 2px 5px;
					box-sizing: border-box;
					border-top-left-radius: 12px;
					border-bottom-left-radius: 12px;

					span {
						margin-left: 3px;
					}
				}
			}

			.head-bottom {
				margin-top: 10px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 12px;
				color: #999999;
			}
		}

		.goods-recommend {
			margin-top: 10px;
			padding: 10px;
			background-color: #FFFFFF;

			.van-cell {
				padding: 0;
			}

			.van-cell::after {
				border-bottom: none;
			}

			.recommend-list {
				margin-top: 5px;
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				width: 100%;
				overflow-x: auto;

				.recommend-item {
					display: flex;
					flex-direction: column;

					.item-image {
						width: 100px;
						height: 100px;
						border-radius: 5px;
					}

					.item-name {
						font-size: 14px;
						margin-top: 5px;
					}

					.item-price {
						font-size: 14px;
						color: #fe6600;
						margin-top: 3px;
					}
				}
			}

		}

		.goods-evaluate {
			margin-top: 10px;
			background-color: #FFFFFF;

			.van-cell {
				padding: 5px 10px;
			}
		}

		.store-list {
			margin-top: 10px;
			background-color: #FFFFFF;
			padding: 10px;
			.btnMoer{
				border: none;
				background: #FFFFFF;
				font-size: 14px;
			}

			.store-number {
				font-size: 14px;
				margin-bottom: 10px;
			}

			.store-item {
				display: flex;
				width: 100%;
				justify-content: space-between;
				border-top: 1px solid #F0F0F0;
				padding-top: 10px;

				.left {
					display: flex;
					flex-direction: column;

					.store-name {
						font-size: 14px;
						color: #333333;
					}

					.store-address {
						font-size: 10px;
						color: #999999;
						margin-top: 5px;
					}
				}

				.right {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
justify-content: flex-end;
					.icon {
						display: flex;
					}

					.store-distance {
						display: flex;
						align-items: flex-end;
						font-size: 10px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}
		}

		.atOnceOrder {
			width: 100%;
			height: 45px;
			// border: solid 1px #000;
			background: #f6f6f6;
			position: fixed;
			bottom: 0;
			font-size: 14px;
			display: flex;

			.leftOrder {
				display: flex;
				align-items: center;
				justify-content: space-around;
				width: 50%;
				height: 100%;

				.orderHome {
					p {
						font-size: 12px;
					}
				}

				.orderOreder {
					p {
						font-size: 12px;
					}
				}
			}

			.rightOrder {
				width: 50%;
				height: 100%;
				background: #FC6228;
				line-height: 45px;
				text-align: center;

				p {
					color: #FFFFFF;
				}
			}
		}
	}

	p {
		margin: 0;
		padding: 0;
	}

	.active {
		// color: red;
		border: solid 1px red;
		padding: 2px 5px;
		border-radius: 8px;
	}

	.commBox {

		.home-top {
			// padding: 0 15px;
			background-color: #FFFFFF;
			box-sizing: border-box;
			width: 100%;

			.home-swipe {
				width: 100%;
				height: 210px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.topInfo {
				font-size: 16px;
				padding: 0 5px;

				.priceCon {
					margin: 10px 0;
					display: flex;
					align-items: center;

					.price {
						font-size: 18px;
						color: red;
					}

					.originalPrice {
						color: #666;
						font-size: 14px;
						// text-decoration: line-through;
						margin-left: 5px;
					}

					.discount {
						font-size: 12px;
						background-color: red;
						color: #FFFFFF;
						padding: 1px 5px;
						margin-left: 5px;
					}
				}
			}

		}

		.storeList {
			margin: 15px 0;
			font-size: 16px;
			padding: 0px 5px;
			background: #FFFFFF;

			.store {
				margin: 10px 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 14px;
				border-bottom: solid 1px #ccc;

				.left {}

				.right {
					.icon {
						width: 50px;
						display: flex;
						justify-content: space-between;
					}
				}
			}
		}

		.setMeal {
			font-size: 16px;
			padding: 10px 5px;
			background: #FFFFFF;

			.mealList {
				margin: 10px 0;
				display: flex;
				font-size: 14px;

				li {
					margin: 0 5px;
				}
			}
		}

		.buyKnow {
			margin: 15px 0;
			font-size: 16px;
			padding: 0 5px;
			background: #FFFFFF;

			.makeWay {
				margin: 10px 0;
				display: flex;
				align-items: center;
			}
		}


	}
</style>
